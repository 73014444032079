import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  avatars: {
    display: "flex",
    flexWrap: "wrap",
    width: 500,
    alignItems: "center",
    "@media (max-width: 470px)": {
      justifyContent: "space-between",
      width: 290,
    },
  },
}));
const Avatars = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: "1.5em" }}>
        NUESTRO EQUIPO
      </Typography>
      <div className={classes.avatars}>
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Arturo Mendez - Monteolivo.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/jessica.png"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Bianka Guarachi - Planex.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Cesia Davila - Planex.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Freddy Melgar - Monteolivo.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Jose Miguel Sanchez - Monteolivo.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Juan Paz - Planex.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Miguel Angel Vargas - Planex.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Rita Rivero - Monteolivo.jpeg"
        />
        <Avatar
          style={{
            margin: 8,
            width: 75,
            height: 75,
            boxShadow: "1px 1px 7px 0.3px black",
          }}
          src="/photos/Sandy Guzman - Monteolivo.jpeg"
        />
      </div>
    </div>
  );
};

export default Avatars;
