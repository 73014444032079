import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
const useStyles = makeStyles(() => ({
  timeline: {
    // height: "auto",
    maxWidth: 800,
    margin: "0 auto",
    position: "relative",

    "& ul": {
      listStyle: "none",
    },
    "& ul li": {
      padding: 20,
      backgroundColor: "#1e1f22",
      color: "white",
      borderRadius: 10,

      marginBottom: 20,
    },
    "& ul li:last-child ": {
      marginBottom: 0,
    },
    "@media only screen and (min-width: 768px)": {
      "&:before": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
        width: "2px",
        height: "100%",
        backgroundColor: "gray",
      },
      "& ul li": {
        width: "50%",
        position: "relative",
        marginBottom: "50px",
      },
      "& ul li:nth-child(odd)": {
        float: "left",
        clear: "right",
        transform: "translateX(-30px)",
        borderRadius: "20px 0px 20px 20px",
      },
      "& ul li:nth-child(even)": {
        float: "right",
        clear: "left",
        transform: "translateX(30px)",
        borderRadius: "0px 20px 20px 20px",
      },
      "& ul li::before": {
        content: "''",
        position: "absolute",
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        backgroundColor: "gray",
        top: "0px",
      },
      "& ul li:nth-child(odd)::before": {
        transform: "translate(50%, -50%)",
        right: "-30px",
      },
      "& ul li:nth-child(even)::before": {
        transform: "translate(-50%, -50%)",
        left: "-30px",
      },

      "& ul li:hover::before": {
        backgroundColor: "aqua",
      },
    },
  },
  timelineContent: {
    "&  h1": {
      fontWeight: 500,
      fontSize: 25,
      lineHeight: 30,
      marginBottom: 10,
    },
    "&  p": {
      fontSize: 16,
      //   lineHeight: 30,
      fontWeight: 300,
    },
    "&  .date": {
      fontSize: 12,
      fontWeight: 300,
      marginBottom: 10,
      letterSpacing: 2,
    },
    "@media only screen and (min-width: 768px)": {
      "& .date": {
        position: "absolute",
        top: "-30px",
      },
    },
  },
  dateStyle: {
    fontSize: "1.5rem !important",
    fontWeight: "bold !important",
    fontFamily: "Montserrat !important",
    padding: "0 !important",
  },
}));
const Timeline = () => {
  const classes = useStyles();
  return (
    <VerticalTimeline>
      <TimelineContent date={2000}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/1.png" />
        <Typography>
          La fundación de la empresa fue en el año 2000 y empezó a funcionar las
          salas en el mes de octubre
        </Typography>
      </TimelineContent>
      <TimelineContent date={2001}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/2.png" />
        <Typography>
          Tenemos una red de funeraria a nivel nacional que cubrimos en todo
          Bolivia
        </Typography>
      </TimelineContent>
      <TimelineContent date={2013}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/3.png" />
        <Typography>Hemos atendido a miles de familias</Typography>
      </TimelineContent>
      <TimelineContent date={2018}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/4.png" />
        <Typography>
          Fuimos solidarios con los que fallecieron en la tragedia de cárcel de
          Palmasola al donar 23 cofres ecológicos para que pudieran dar
          Cristiana Sepultura
        </Typography>
      </TimelineContent>
      <TimelineContent date={2019}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/5.png" />
        <Typography>
          Hemos podido repatriar a compatriotas que fallecieron en el extranjero
          y también expatriar a personas extranjeras a su País
        </Typography>
      </TimelineContent>
      <TimelineContent date={2020}>
        <img style={{ width: "100%", borderRadius: 4 }} src="/landing/6.png" />
        <Typography>
          Hicimos planes funerarios económicos para personas fallecidas por la
          pandemia del Covid-19
        </Typography>
      </TimelineContent>
    </VerticalTimeline>
  );
};

const TimelineContent = ({ date, children }) => {
  const classes = useStyles();
  return (
    <VerticalTimelineElement
      icon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            style={{
              width: "25px",
            }}
            src="/img/logo-mini.png"
          />
        </div>
      }
      date={date}
      className="vertical-timeline-element--work"
      dateClassName={classes.dateStyle}
    >
      {children}
    </VerticalTimelineElement>
  );
};
export default Timeline;
