import React from "react";
import Menu from "../components/Menu";
import Layout from "../components/Layout";
import { Background, Text } from "../components/Landing";
import Gallery from "../components/Gallery";
import algoliasearch from "algoliasearch/lite";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import Testimonials from "../components/Testimonials";
import Timeline from "../components/Timeline";
import Avatars from "../components/Avatars";
const useStyles = makeStyles(() => ({
  divGalleryText: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    zIndex: 15,
    "@media (max-width: 850px)": {
      minHeight: "0",
      flexDirection: "column-reverse",
    },
  },
}));

const Index = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Menu></Menu>
      <div className={classes.divGalleryText}>
        <Gallery />
        <Text />
      </div>

      <div style={{ height: 200 }}></div>
      <div>
        <Typography
          align="center"
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
        >
          NUESTRA HISTORIA
        </Typography>
        <Timeline />
      </div>
      <div>
        <Testimonials />
      </div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatars />
      </div>
      <Background></Background>
    </Layout>
  );
};

export default Index;
